import React from 'react'
import SbEditable from 'storyblok-react'
import renderBlok from '@renderBlok'
import { TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'

const useStyles = makeStyles({
  tableRowRoot: {},
})

const TableMatrixRow = ({ blok }) => {
  const { row } = blok
  const component = get(row, '[0].component')
  const variant = get(row, '[0].variant')
  const classes = useStyles({ component, variant })
  return (
    <SbEditable content={blok}>
      <TableRow className={classes.tableRowRoot}>
        {row.map((innerElement, idx) => renderBlok(innerElement, { idx }))}
      </TableRow>
    </SbEditable>
  )
}

export default TableMatrixRow
